<template>
  <form-observer ref="observer" @submit.prevent="onSubmit">
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Usuario"
          maxlength="50"
          placeholder="Usuario"
          v-model="form.username"
          autofocus
        />
      </div>
      <div class="column">
        <input-base
          rules="required|email"
          label="E-mail"
          maxlength="200"
          placeholder="E-mail"
          v-model="form.email"
        />
      </div>
      <div class="column">
        <input-base
          :rules="form.id ? '' : 'required'"
          label="Contraseña"
          maxlength="150"
          type="password"
          placeholder="Contraseña"
          v-model="form.password"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="required"
          label="Nombres"
          maxlength="200"
          placeholder="Nombres"
          v-model="form.nombres"
        />
      </div>
      <div class="column">
        <input-base
          rules="required"
          label="Apellidos"
          maxlength="200"
          placeholder="Apellidos"
          v-model="form.apellidos"
        />
      </div>
      <div class="column">
        <input-base
          rules="numeric|digits:8"
          label="DNI"
          maxlength="8"
          placeholder="DNI"
          v-model="form.dni"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules="numeric|digits:11"
          label="RUC"
          maxlength="11"
          placeholder="RUC"
          v-model="form.ruc"
        />
      </div>
      <div class="column">
        <input-base
          rules="numeric"
          label="Celular"
          maxlength="11"
          placeholder="Celular"
          v-model="form.celular"
        />
      </div>
      <div class="column">
        <input-base
          rules="numeric"
          label="Teléfono"
          maxlength="11"
          placeholder="Teléfono"
          v-model="form.telefono"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <input-base
          rules=""
          label="Dirección"
          maxlength="150"
          placeholder="Dirección"
          v-model="form.direccion"
        />
      </div>
      <div class="column">
        <select-search-uri
          rules=""
          label="Región / Provincia / Distrito" 
          uri="/ubigeo/select"
          v-model="form.id_ubigeo"
          expanded
        />
      </div>
      <div class="column">
        <input-base
          rules=""
          label="Descripción"
          maxlength="250"
          placeholder="Descripción"
          v-model="form.descripcion"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column is-3">
        <input-image-base
          rules=""
          label="Foto"
          placeholder="Foto"
          v-model="form.imagen_file"
          :src="form.imagen_url"
          @remove="() => form.imagen_remove = true"
        />
      </div>
      <div class="column is-3">
        <radio-base
          rules=""
          label="Sexo"
          :options="[{id: 'M',value: 'Masculino'}, {id: 'F',value: 'Femenino'}]"
          v-model="form.sexo"
        />
      </div>
      <div class="column is-3">
        <radio-base
          rules="required"
          label="Estado"
          :options="[{id: '1',value: 'Activado'}, {id: '0',value: 'Desactivado'}]"
          v-model="form.estado"
        />
      </div>
      <div class="column is-3" v-if="isRoot">
        <radio-base
          rules="required"
          label="Tipo Usuario"
          :options="[{id: '0',value: 'Normal'}, {id: '1',value: 'Root'}, {id: '2',value: 'Administrador'}]"
          v-model="form.tipo_user"
        />
      </div>
    </div>
    <p class="is-pulled-right is-italic" v-if="form.updated_at">
      <span class="has-text-weight-semibold">Actualizado:</span> {{form.updated_at}}
    </p>
    <div>
      <b-button
        native-type="submit"
        type="is-primary"
        icon-left="save"
        :loading="loading"
        class="mt-3 mr-3"
        >Guardar</b-button
      >
      <b-button
        type="is-secondary"
        icon-left="times"
        :loading="loading"
        class="mt-3"
        @click="() => $emit(`oncancel`)"
        >Cancelar</b-button
      >
    </div>
  </form-observer>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
  props:{
    value:{
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loading: false,
    };
  },
  computed:{
    ...mapGetters({
      isRoot: "auth/isRoot",
    }),
    form:{
      get(){
        return this.value ?? {}
      },
      set(e){
        this.$emit("input", e)
      }
    }
  },
  methods: {
    async onSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      //
      this.loading = true;
      const row = await this.$store.dispatch(`users/save`, this.form)
      if(row){
        const status = this.form.id ? `editar` : `crear`
        this.$store.dispatch(
          "app/setToastSuccess",
          `Se acaba de ${status} el usuario: "${row.persona.nombre_completo}"`
        );
        this.$emit("onsuccess")
      }
      this.loading = false;
    },
  },
};
</script>
