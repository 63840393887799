<template>
  <div>
    <title-bar
      :items="[
        { title: 'Usuarios', to: `/users` },
        { title: title_page },
      ]"
    >
    </title-bar>
    <div class="box">
      <Form 
        v-model="row"
        @oncancel="() => $router.back()" 
        @onsuccess="() => $router.back()"
      />
    </div>
  </div>
</template>

<script>
import Form from "../components/nuevo/Form";
export default {
  components: {
    Form,
  },
  data(){
    return {
      row: {}
    }
  },
  computed:{
    name_store(){
      return `users`
    },
    title_page(){
      const {id} = this.$route.query
      return (id ? `Editar` : `Nuevo`) + ` Usuario`
    }
  },
  async created(){
    const {id} = this.$route.query
    if(id){
      const data = await this.$store.dispatch(`${this.name_store}/get`, id)
      if(data){
        this.setForm(data)
      } else {
        this.$store.dispatch("app/set404", true)
      }
    } else {
      this.setForm()
    }
  },
  methods: {
    setForm(data = { persona:{} }){
      this.$set(this,'row',{
        id: data.id ?? null,
        username: data.username ?? '',
        email: data.persona.email ?? '',
        password: '' ?? '',
        nombres: data.persona.nombres ?? '',
        apellidos: data.persona.apellidos ?? '',
        dni: data.persona.dni ?? '',
        ruc: data.persona.ruc ?? '',
        celular: data.persona.celular ?? '',
        telefono: data.persona.telefono ?? '',
        direccion: data.persona.direccion ?? '',
        id_ubigeo: data.persona.id_ubigeo ?? null,
        descripcion: data.persona.descripcion ?? '',
        imagen_file: null,
        imagen_url: data.persona.imagen_url ?? null,
        sexo: data.persona.sexo ?? 'M',
        estado: data.persona.estado ?? '1',
        tipo_user: data.persona.es_root ? '1' : (data.persona.es_admin ? '2' : '0'),
        updated_at: data.updated_at ?? null,
      })
    }
  }
};
</script>